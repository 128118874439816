import Vue from "vue";
import VueRouter from "vue-router";
import { appSettings } from "@/services/appSettings/index";
import store from "@/store";
import defaultLayout from "../layouts/default.vue";
import home from "../modules/home/home";
import errorLayout from "../layouts/404.vue";
import { userService } from "@/services/user/index";
import { admin } from "../modules/admin/routes";
import { registerPage } from "../modules/register/routes";
import { loginPage } from "../modules/login/routes";
import { dashboard } from "../modules/dashboard/routes";
import { profile } from "../modules/profile/routes";
import { test } from "../modules/test/routes";
import { diagnosticTest } from "../modules/diagnosticTest/routes";
import { developmentTest } from "../modules/developmentTest/routes";
import { myStudies } from "../modules/myStudies/routes";
import { payment } from "../modules/payment/routes";
import { packets } from "../modules/packets/routes";
import { reports } from "../modules/reports/routes";
import { basket } from "../modules/basket/routes";
import { landingPage } from "../modules/landingPage/routes";
import { forgetPassword } from "../modules/forgetPassword/routes"
import { blog } from "../modules/blog/routes"
import { aboutUs } from "../modules/aboutUs/routes"
import { contactUs } from "../modules/contactUs/routes"
import { legal } from "../modules/legal/routes"
import { help } from "../modules/help/routes"
import { isCookieEnabled, getCookie } from "@/common/helpers/user"
import cookieBlocked from "../layouts/cookieBlocked.vue";
import { howItWorks } from "../modules/howItWorks/routes"
import { diagnosticTestGtb } from "../modules/diagnosticTestGtb/routes";
import { studentList } from "../modules/studentList/routes";
import { dashboardPreview } from "../modules/dashboardPreview/routes";
import { parentRegister } from "../modules/parentRegister/routes"
import { dashboardPreviewStudent } from "../modules/dashboardPreviewStudent/routes";
import { mediaPage } from "../modules/media/routes"

Vue.use(VueRouter);

const routes = [
  ...registerPage,
  ...loginPage,
  ...landingPage,
  ...forgetPassword,
  ...blog,
  ...aboutUs,
  ...contactUs,
  ...howItWorks,
  ...parentRegister,
  {
    path: "",
    component: defaultLayout,
    children: [
      {
        path: "",
        name: "home",
        component: home,
        meta: {
          acceptedRoles: "ALL",
        },
        beforeEnter: (to, from, next) => {
          if ("STUDENT".includes(userService.userRole())) {
            let forceFreeTrialPage = store.state.user.forceFreeTrialPage
            let forceFreeTrialGtbTtbPage = store.state.user.forceFreeTrialGtbTtbPage
            if (forceFreeTrialPage) {
              next({ name: "diagnosticTest" });
              return;
            }
            if (forceFreeTrialGtbTtbPage) {
              next({ name: "diagnosticTestGtb" });
              return;
            }
            if (userService.userPackets().length < 1) {
              next({ name: "packets" });
              return;
            }
            next({ name: "dashboard" });
            return;
          } else if ("ADMIN".includes(userService.userRole())) {
            next({ name: "adminPacketList" });
            return;
          } else if ("SUPERADMIN".includes(userService.userRole())) {
            next({ name: "adminBasePacketList" });
            return;
          } else if ("SCL_COORDINATOR".includes(userService.userRole())) {
            next({ name: "studentList" });
            return;
          } else if (
            "MATH_ADMIN,SCIENCE_ADMIN,SOCIAL_ADMIN,TURKISH_ADMIN".includes(
              userService.userRole()
            )
          ) {
            next({ name: "basePacketTests" });
            return;
          } else if ("STUDENT_PARENT".includes(userService.userRole())) {
            next({ name: "dashboardPreviewStudent" });
            return;
          } else {
            next({ name: "dashboard" });
            return;
          }
        },
      },
      ...admin,
      ...dashboard,
      ...profile,
      ...test,
      ...diagnosticTest,
      ...developmentTest,
      ...myStudies,
      ...payment,
      ...packets,
      ...reports,
      ...basket,
      ...legal,
      ...help,
      ...diagnosticTestGtb,
      ...studentList,
      ...dashboardPreview,
      ...dashboardPreviewStudent,
      ...mediaPage,
    ],
  },
  {
    path: "/404",
    name: "404 Error",
    component: errorLayout,
    meta: {
      acceptedRoles: "ALL",
      notRequiresAuth: true
    },
  },
  {
    path: "/cookie-blocked",
    name: "cookieBlocked",
    component: cookieBlocked,
    meta: {
      acceptedRoles: "ALL",
      notRequiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!isCookieEnabled()) {
        next();
      } else {
        next({ name: "landingPage" });
      }
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (to.name != from.name) return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (typeof window !== 'undefined') {
  let documentTitle = `${process.env.VUE_APP_TITLE}`;
  if (to.meta.title) {
    documentTitle = `${to.meta.title}`;
  }
  document.title = documentTitle;

  // Arka plan rengi (varsayilan beyaz)
  if (to.meta.bgColorGray) appSettings.bgColorGray(true);
  else appSettings.bgColorGray(false);
  if (to.meta.bgColorBlue) appSettings.bgColorBlue(true);
  else appSettings.bgColorBlue(false);

  if (!store.state.user.baskeIsLoaded) {
    // sepet, tekrar sayfa yuklenene kadar cagrilmasin diye
    await userService.getBasketList();
    store.dispatch("user/updateBaskeIsLoaded", true);
  }

  if (to.meta.notRequiresAuth) {
    next();
    return;
  }

  let accessTokenB = isCookieEnabled() ? getCookie("accessTokenB") : false
  if (accessTokenB && !userService.isAuthenticated()) {
    await userService.pullUserInfo();
    await userService.pullUserPermissions();
    await userService.getUserPackets(); // Bu sorgu burada olmazsa login olan kullanici icin "home" yonlendirmesi olan her yerde "beforeEnter"-daki kontrol yuzunden (yani paketler sorgusu cagrilmadigi icin ve length degeri 0 olacagindan) ana sayfa degil de paketlerim sayfasina yonlendiriliyordu. Kullanici login-se paketleri getirildigi icin bu sorun olmayacaktir
  } else if (
    !userService.isAuthenticated() &&
    to.name !== "login" &&
    to.name !== "register"
  )
    next({ name: "landingPage" });
  //Yetkilere göre router ayarı
  if (
    to.meta.acceptedRoles.includes(userService.userRole()) ||
    to.meta.acceptedRoles == "ALL"
  ) {
    next();
  } else {
    next({ name: "404 Error" });
  }

  }
});

export default router;

<template>
  <div id="app">
    <Manage />
    <cookiesPopup />
    <updatePrompt />
    <networkError />
    <router-view />
    <!-- <ChatBoxWithoutLogin v-if="!isLoggedIn" /> -->
  </div>
</template>

<script>
import Manage from "@/common/components/management/manage.vue";
import { manage } from "@/common/eventBuses/manage";
import cookiesPopup from "@/common/components/kvkk/cookiesPopup";
import updatePrompt from "./common/components/prompt/updatePrompt.vue";
import networkError from "./common/components/prompt/networkError.vue";
// import ChatBoxWithoutLogin from "@/common/components/chatBoxWithoutLogin/index";

export default {
  components: {
    Manage,
    cookiesPopup,
    updatePrompt,
    networkError,
    // ChatBoxWithoutLogin
  },
  created() {
    document.body.className = "theme-1";
  },
  mounted() {
    document.addEventListener("keypress", this.documentKeypressClick);
  },
  beforeDestroy() {
    document.removeEventListener("keypress", this.documentKeypressClick);
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.user.isLoggedIn_Store;
    },
  },
  methods: {
    documentKeypressClick(e) {
      this.keypressArr += e.key;
      if (this.keypressArr.includes("appcontrolpanel")) {
        manage.$emit("changeStatus", true);
        this.keypressArr = "";
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #242424; // #2c3e50
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"z-40 flex items-center justify-center fixed h-14 w-14 text-white gradient-bg shadow-[0_1px_28px_4px_rgba(82,82,82,0.3)] transition-all hover:scale-105 chatbox-button",class:[_vm.showChatBox ? '' : 'pulseBtn', _vm.chatIconPosition],on:{"click":function($event){_vm.showChatBox = !_vm.showChatBox}}},[(_vm.showChatBox)?_c('div',{staticClass:"w-min"},[_c('svg-icon',{staticClass:"w-4 h-4",attrs:{"name":"close"}})],1):_c('div',{staticClass:"p-2 -mt-2"},[_c('img',{attrs:{"src":_vm.botSvg}})])]),_c('div',{staticClass:"z-30 fixed right-10 bottom-6 flex flex-col gap-3 w-[300px] bg-white shadow-[0_1px_28px_4px_rgba(82,82,82,0.3)] rounded-lg px-3 py-4 transition-all",class:_vm.showQuestionYesOrNo && !_vm.showChatBox
        ? ''
        : 'scale-50 opacity-0 pointer-events-none'},[_c('div',{staticClass:"text-left text-sm font-semibold"},[_vm._v(" Merhaba, seni burada görmekten mutluluk duydum! Edunext Matematik - TYT 2025 paketinde %10-luk indirim kuponumuz var. Denemek ister misin? ")]),_c('hr',{staticClass:"mr-8"}),_vm._m(0)]),_c('div',{staticClass:"z-30 fixed right-10 bottom-6 flex flex-col gap-3 w-[300px] bg-white shadow-[0_1px_28px_4px_rgba(82,82,82,0.3)] rounded-lg px-3 py-4 transition-all",class:_vm.showQuestionYesOrNoWithImage && !_vm.showChatBox
        ? ''
        : 'scale-50 opacity-0 pointer-events-none'},[_vm._m(1),_c('div',{staticClass:"text-left text-sm font-semibold"},[_vm._v(" Merhaba, seni burada görmekten mutluluk duydum! Edunext Matematik - TYT 2025 paketinde %10-luk indirim kuponumuz var. Denemek ister misin? ")]),_c('hr',{staticClass:"mr-8"}),_vm._m(2)]),_c('div',{staticClass:"transition-all fixed sm:h-[calc(100vh_-_180px)] h-[calc(100vh_-_52px)] sm:max-h-[800px] bg-white sm:rounded-xl sm:shadow-[0_1px_28px_4px_rgba(82,82,82,0.3)] z-40",class:[
      _vm.showChatBox ? '' : 'scale-50 opacity-0 pointer-events-none',
      _vm.chatBoxWidthIsSmall ? 'sm:w-[380px] w-full' : 'w-[760px]',
      _vm.chatBoxPosition
    ]},[_c('header',{staticClass:"flex justify-between gradient-bg text-white px-3 h-[64px] relative sm:rounded-xl shadow-[0_1px_6px_2px_rgba(82,82,82,0.3)]"},[_c('div',{staticClass:"flex items-center justify-center text-lg font-semibold"},[_vm._v(" "+_vm._s(_vm.chatboxName)+" ")]),_c('div',{staticClass:"flex items-center justify-center gap-1"},[_c('button',{staticClass:"flex items-center justify-center w-9 h-9 rounded-full hover:bg-inputBackground",class:_vm.showSettingsMenu ? 'bg-inputBackground' : '',on:{"click":function($event){_vm.showSettingsMenu = !_vm.showSettingsMenu}}},[_c('svg-icon',{staticClass:"w-3.5 h-3.5 rotate-90",attrs:{"name":"dots"}})],1),_c('button',{staticClass:"hidden sm:flex items-center justify-center w-9 h-9 rounded-full hover:bg-inputBackground",class:_vm.chatBoxWidthIsSmall ? '' : 'bg-inputBackground',on:{"click":function($event){_vm.chatBoxWidthIsSmall = !_vm.chatBoxWidthIsSmall}}},[_c('svg-icon',{staticClass:"w-3.5 h-3.5",attrs:{"name":"resize"}})],1),_c('button',{staticClass:"flex items-center justify-center w-9 h-9 rounded-full hover:bg-inputBackground",on:{"click":function($event){_vm.showChatBox = !_vm.showChatBox}}},[_c('svg-icon',{staticClass:"w-3.5 h-3.5",attrs:{"name":"chevron-down"}})],1)]),_c('div',{staticClass:"flex flex-col gap-3 absolute top-[64px] left-0 px-3 py-2 mx-3 w-[calc(100%_-_24px)] rounded-xl bg-white text-black-800 z-10 shadow-[0_1px_28px_0px_rgba(82,82,82,0.3)] transition-all",class:_vm.showSettingsMenu ? '' : 'scale-50 opacity-0 pointer-events-none'},[_c('div',{staticClass:"flex justify-between items-center gap-3"},[_c('div',{staticClass:"text-sm font-semibold"},[_vm._v("Yazı tipi boyutu")]),_c('div',{staticClass:"flex items-center gap-3"},[_c('button',{staticClass:"flex items-center justify-center text-dolgerBlue w-6 h-6 font-semibold rounded-full hover:bg-dolgerBlue hover:text-white transition-all",class:_vm.messageFontSize == 14
                  ? 'scale-50 opacity-0 pointer-events-none'
                  : '',on:{"click":function($event){return _vm.resizeFontSize()}}},[_c('svg-icon',{staticClass:"w-3.5 h-3.5",attrs:{"name":"reset"}})],1),_c('div',{staticClass:"flex items-center gap-2 bg-dolgerBlue bg-opacity-10 rounded-full shadow-inner"},[_c('button',{staticClass:"flex items-center justify-center w-6 h-6 text-dolgerBlue font-semibold rounded-full",class:!_vm.disableMinusButtonOfFontSize
                    ? 'hover:bg-dolgerBlue hover:text-white'
                    : 'opacity-20',attrs:{"disabled":_vm.disableMinusButtonOfFontSize},on:{"click":function($event){return _vm.resizeFontSize('minus')}}},[_vm._v(" - ")]),_c('div',{staticClass:"text-sm font-semibold text-dolgerBlue w-8 cursor-default"},[_vm._v(" "+_vm._s(_vm.messageFontSize)+" ")]),_c('button',{staticClass:"flex items-center justify-center w-6 h-6 text-dolgerBlue font-semibold rounded-full",class:!_vm.disablePlusButtonOfFontSize
                    ? 'hover:bg-dolgerBlue hover:text-white'
                    : 'opacity-20',attrs:{"disabled":_vm.disablePlusButtonOfFontSize},on:{"click":function($event){return _vm.resizeFontSize('plus')}}},[_vm._v(" + ")])])])])])]),_c('ul',{ref:"chatbox",staticClass:"flex flex-col gap-3 sm:h-[calc(100vh_-_316px)] h-[calc(100vh_-_188px)] sm:max-h-[664px] overflow-y-auto px-3 py-4 text-left main-div bg-gray-100",staticStyle:{"overflow-anchor":"none"}}),_c('hr'),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center justify-center gap-1 h-[16px] max-h-[16px] w-full gradient-bg text-white font-semibold text-2xs text-dolgerBlue px-3 shadow-[0_-1px_10px_rgba(82,82,82,0.3)] overflow-hidden"},[_vm._v(" Soru sorma limitiniz : "),_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('div',{key:_vm.userMessageLimit,staticClass:"text-2xs"},[_vm._v(" "+_vm._s(_vm.userMessageLimit)+" ")])])],1),_c('div',{staticClass:"flex items-center justify-center gap-1 w-full bg-white h-[56px] px-3 sm:rounded-b-xl"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"w-full h-[56px] border-none outline-none resize-none text-sm py-4",attrs:{"id":"chatMessageId","placeholder":"Mesaj giriniz..."},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}}),_c('button',{staticClass:"flex items-center justify-center px-3 text-dolgerBlue font-semibold w-12 h-12 rounded-full",class:[
            _vm.message ? 'visible' : 'invisible',
            _vm.answerLoading
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-inputBackground',
          ],attrs:{"id":"send-btn","disabled":_vm.answerLoading},on:{"click":function($event){return _vm.sendMessageButton()}}},[_c('svg-icon',{staticClass:"w-5 h-5",attrs:{"name":"send"}})],1)])]),(_vm.showWelcomePage)?_c('welcomeMessage',{on:{"input":function($event){return _vm.showWelcomePageFunc()}}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid grid-cols-2 gap-2"},[_c('button',{staticClass:"text-left text-sm text-dolgerBlue font-semibold w-min"},[_vm._v(" Evet ")]),_c('button',{staticClass:"text-left text-sm text-dolgerBlue font-semibold w-min"},[_vm._v(" Hayır ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center w-full h-40 bg-gray-100 rounded-lg shadow"},[_c('img',{attrs:{"src":require("@/assets/images/illustrations/development-bigwidth.webp"),"alt":"Development"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid grid-cols-2 gap-2"},[_c('button',{staticClass:"text-left text-sm text-dolgerBlue font-semibold w-min"},[_vm._v(" Evet ")]),_c('button',{staticClass:"text-left text-sm text-dolgerBlue font-semibold w-min"},[_vm._v(" Hayır ")])])
}]

export { render, staticRenderFns }
import axios from "@/services/axios/authAxios";

const sendMessage = async (message, pageUrl, basePacketId) => {
  return await axios.post("ai/chat", {
    message: message,
    pageUrl: pageUrl,
    basePacketId: basePacketId,
  });
};

const getMessages = async (page, limit) => {
  return await axios.get("ai/chatMessage", {
    params: { page, limit },
  });
};

const getQuestion = async (questionId) => {
  return await axios.get("ai/question", {
    params: { questionId },
  });
};

const acceptChat = async () => {
  return await axios.post("ai/acceptChat");
};

export { sendMessage, getMessages, getQuestion, acceptChat };

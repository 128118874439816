const moduleComponent = () =>
  import(/* webpackChunkName: "media" */ "./index");
const mediaImage = () =>
  import(/* webpackChunkName: "mediaImage" */ "./image/index");
const mediaVideo = () =>
  import(/* webpackChunkName: "mediaVideo" */ "./video/index");

export const mediaPage = [
  {
    path: "/media",
    name: "media",
    component: moduleComponent,
    meta: {
      title: "Medya",
      acceptedRoles: "STUDENT",
      bgColorGray: true,
    },
    children: [
      {
        path: "/media/image/:id",
        name: "mediaImage",
        component: mediaImage,
        meta: {
          title: "Medya Resim",
          acceptedRoles: "STUDENT",
          bgColorGray: true,
        },
      },
      {
        path: "/media/video/:id",
        name: "mediaVideo",
        component: mediaVideo,
        meta: {
          title: "Medya Video",
          acceptedRoles: "STUDENT",
          bgColorGray: true,
        },
      },
    ],
  },
];

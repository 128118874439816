<template>
  <div
    class="absolute left-0 top-0 w-full h-full bg-white sm:rounded-xl border-4 border-dolgerBlue transition-all shadow-[0_1px_8px_6px_rgba(28,123,255,0.3)]"
  >
    <div class="flex flex-col justify-between gap-3 h-full px-4 py-4 text-left">
      <div ref="mainBox" class="flex flex-col gap-3 overflow-y-auto">
        <div class="text-2xl font-semibold text-dolgerBlue">
          Merhaba, Ben Edunext’in Yapay Zekası Edu Koç! 🤖❤️
        </div>
        <div ref="divBox" class="text-sm text-black-400"></div>
        <!-- <div class="text-sm text-black-400">
          birçok farklı konuda sana yardımcı olabilecek ücretsiz bir yapay zeka
          asistanıdır.
        </div>
        <div class="grid grid-cols-12 pt-3">
          <div class="col-span-2">
            <svg-icon name="alert-circle" class="w-8 h-8" />
          </div>
          <div class="col-span-10">
            <div class="text-sm font-semibold">Yanıtlar hatalı olabilir</div>
            <div class="text-sm text-black-400">
              kişiler, yerler veya gerçekler hakkında yanlış bilgi verebilir.
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12">
          <div class="col-span-2">
            <svg-icon name="lock" class="w-8 h-8" />
          </div>
          <div class="col-span-10">
            <div class="text-sm font-semibold">Hassas bilgileri paylaşma</div>
            <div class="text-sm text-black-400">
              Sohbetler incelenebilir ve modellerimizi eğitmek için
              kullanılabilir.
            </div>
          </div>
        </div> -->
      </div>
      <div class="flex flex-col gap-3">
        <!-- <div class="text-xs text-black-400 px-4">
          Devam ettiğinizde <b class="text-xs">Şartlarımızı</b> onayladığın ve
          <b class="text-xs">Gizlilik Politakımızı</b> okuduğun kabul edilir.
        </div> -->
        <main-button
          @click="acceptChatFunc()"
          :text="'Devam et'"
          :size="1"
          :disabled="textLoading"
          class="w-full my-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { acceptChat } from "../helpers";
import mainButton from "@/common/components/buttons/mainButton.vue";

export default {
  data() {
    return {
      typeWriterMessage: "",
      typeWriterIndex: 0,
      typeWriterSpeed: 30,
      textLoading: false
    };
  },
  components: {
    mainButton,
  },
  mounted() {
    // <div class="text-sm text-black-400">
    //       Bu platformdaki görevim, sana destek olmak ve öğrenme sürecinde
    //       gelişimini takip etmek. İşte sana yardımcı olacağım alanlar:
    //     </div>
    //     <div class="flex flex-col">
    //       <div class="text-sm font-semibold">1️⃣ Platform Kullanımı:</div>
    //       <div class="text-sm text-black-400">
    //         Her sayfada yapman gerekenleri bana sorabilir ve nasıl
    //         ilerleyeceğini öğrenebilirsin.
    //       </div>
    //     </div>
    //     <div class="flex flex-col">
    //       <div class="text-sm font-semibold">2️⃣ Gelişim Takibi:</div>
    //       <div class="text-sm text-black-400">
    //         Hedeflerini izler, öğrenmeni destekler ve düzenli olarak sana
    //         rehberlik ederim. Bu konularda da bana sorular sorabilirsin.
    //       </div>
    //     </div>
    //     <div class="text-sm text-black-400">
    //       ⚠️ Unutma! Burada sadece platformla ilgili sorularına cevap vereceğim.
    //       🖥️ Bu sürümde haftalık 20 soru sorma hakkın var.
    //     </div>
    let message = `Merhaba, Ben Edunext’in Yapay Zekası Edu Koç! 🤖❤️
      Bu platformdaki görevim, sana destek olmak ve öğrenme sürecinde gelişimini takip etmek. İşte sana yardımcı olacağım alanlar:
      <br /><br />
      1️⃣ Platform Kullanımı:
      <br />
      Her sayfada yapman gerekenleri bana sorabilir ve nasıl ilerleyeceğini öğrenebilirsin.
      <br /><br />
      2️⃣ Gelişim Takibi:
      <br />
      Hedeflerini izler, öğrenmeni destekler ve düzenli olarak sana rehberlik ederim. Bu konularda da bana sorular sorabilirsin.
      <br /><br />
      ⚠️ Unutma! Burada sadece platformla ilgili sorularına cevap vereceğim. 🖥️ Bu sürümde haftalık 20 soru sorma hakkın var.
      <br /><br />
      Şimdi, ‘Devam Et’ butonuna tıklayarak yolculuğuna başlayabilirsin! 🚀✨`;
    this.typeWriter(message); // efektle ekrana yazilir
  },
  methods: {
    scrollToBottomFunc(isSmoothBehavior) {
      const mainBox = this.$refs.mainBox;
      mainBox?.scrollTo({
        top: mainBox?.scrollHeight,
        ...(isSmoothBehavior && { behavior: "smooth" }),
      });
    },
    acceptChatFunc() {
      acceptChat()
        .then(() => {
          this.$emit("input", false);
        })
        .catch(() => {
          this.$toastAlert({
            type: "error",
            title: this.$t("general.error"),
            message: "Devam edilirken hata oluştu.",
          });
        });
    },
    typeWriter(text) {
      this.typeWriterMessage = text;
      this.typeWriterFunc();
    },
    typeWriterFunc() {
      const divBox = this.$refs.divBox; // mesaj yazilacak alan
      const typedText = divBox; // son mesaj div-i icinde ilk resim ve ikinci metin oldugu icin burasi boyle
      if (!typedText || !this.typeWriterMessage) return; // hata durumunda cik
      // gecici bir div kullanarak HTML icerigini ayristir
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.typeWriterMessage;
      // ayristirilmis dugumleri bir array olarak al
      const nodes = Array.from(tempDiv.childNodes);
      if (this.typeWriterIndex < nodes?.length) {
        const currentNode = nodes[this.typeWriterIndex];
        if (currentNode.nodeType === Node.TEXT_NODE) {
          // eger dugum metinse
          const text = currentNode.textContent;
          this.appendTextCharacterByCharacter(typedText, text);
        } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
          // eger düğüm HTML ise
          typedText.appendChild(currentNode.cloneNode(true));
          this.typeWriterIndex++;
          setTimeout(() => this.typeWriterFunc(), this.typeWriterSpeed);
        }
      } else {
        this.textLoading = false; // ekrana yazma bittiginde mesaj gonder buton tekrar aktif yapilir
        this.scrollToBottomFunc(true);
        this.typeWriterIndex = 0;
        this.typeWriterMessage = "";
      }
    },
    appendTextCharacterByCharacter(targetElement, text) {
      let charIndex = 0;
      const typeChar = () => {
        if (charIndex < text.length) {
          this.textLoading = true; // ekrana yazma efekti varken tekrar mesaj gonderemesin
          // yazarken surekli asagi scroll et
          this.scrollToBottomFunc(true);
          targetElement.innerHTML += text[charIndex];
          charIndex++;
          setTimeout(typeChar, this.typeWriterSpeed);
        } else {
          this.typeWriterIndex++;
          this.typeWriterFunc(); // Bir sonraki dugume gec
        }
      };
      typeChar();
    },
  },
};
</script>

<style>
</style>
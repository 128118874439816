<!-- NOT: 
 Sayfa icindeki "h" yani yukseklik degerleri hesapli yapilmistir. Herhangi height degistiginde digerlerini bozdu mu bakilmali 
-->
<template>
  <div>
    <button
      @click="showChatBox = !showChatBox"
      class="z-40 flex items-center justify-center fixed h-14 w-14 text-white gradient-bg shadow-[0_1px_28px_4px_rgba(82,82,82,0.3)] transition-all hover:scale-105 chatbox-button"
      :class="[showChatBox ? '' : 'pulseBtn', chatIconPosition]"
    >
      <div v-if="showChatBox" class="w-min">
        <svg-icon name="close" class="w-4 h-4" />
      </div>
      <div v-else class="p-2 -mt-2">
        <img :src="botSvg" />
      </div>
    </button>

    <!-- chat acilmadan baslangic mesaji START -->

    <!-- Soru ve evet/hayir cevapli kutu -->
    <div
      class="z-30 fixed right-10 bottom-6 flex flex-col gap-3 w-[300px] bg-white shadow-[0_1px_28px_4px_rgba(82,82,82,0.3)] rounded-lg px-3 py-4 transition-all"
      :class="
        showQuestionYesOrNo && !showChatBox
          ? ''
          : 'scale-50 opacity-0 pointer-events-none'
      "
    >
      <div class="text-left text-sm font-semibold">
        Merhaba, seni burada görmekten mutluluk duydum! Edunext Matematik - TYT
        2025 paketinde %10-luk indirim kuponumuz var. Denemek ister misin?
      </div>
      <hr class="mr-8" />
      <div class="grid grid-cols-2 gap-2">
        <button class="text-left text-sm text-dolgerBlue font-semibold w-min">
          Evet
        </button>
        <button class="text-left text-sm text-dolgerBlue font-semibold w-min">
          Hayır
        </button>
      </div>
    </div>

    <!-- Soru ve evet/hayir cevapli ve resimli kutu -->
    <div
      class="z-30 fixed right-10 bottom-6 flex flex-col gap-3 w-[300px] bg-white shadow-[0_1px_28px_4px_rgba(82,82,82,0.3)] rounded-lg px-3 py-4 transition-all"
      :class="
        showQuestionYesOrNoWithImage && !showChatBox
          ? ''
          : 'scale-50 opacity-0 pointer-events-none'
      "
    >
      <div
        class="flex items-center justify-center w-full h-40 bg-gray-100 rounded-lg shadow"
      >
        <img
          src="@/assets/images/illustrations/development-bigwidth.webp"
          alt="Development"
        />
      </div>
      <div class="text-left text-sm font-semibold">
        Merhaba, seni burada görmekten mutluluk duydum! Edunext Matematik - TYT
        2025 paketinde %10-luk indirim kuponumuz var. Denemek ister misin?
      </div>
      <hr class="mr-8" />
      <div class="grid grid-cols-2 gap-2">
        <button class="text-left text-sm text-dolgerBlue font-semibold w-min">
          Evet
        </button>
        <button class="text-left text-sm text-dolgerBlue font-semibold w-min">
          Hayır
        </button>
      </div>
    </div>
    <!-- chat acilmadan baslangic mesaji END -->

    <div
      class="transition-all fixed sm:h-[calc(100vh_-_180px)] h-[calc(100vh_-_52px)] sm:max-h-[800px] bg-white sm:rounded-xl sm:shadow-[0_1px_28px_4px_rgba(82,82,82,0.3)] z-40"
      :class="[
        showChatBox ? '' : 'scale-50 opacity-0 pointer-events-none',
        chatBoxWidthIsSmall ? 'sm:w-[380px] w-full' : 'w-[760px]',
        chatBoxPosition
      ]"
    >
      <header
        class="flex justify-between gradient-bg text-white px-3 h-[64px] relative sm:rounded-xl shadow-[0_1px_6px_2px_rgba(82,82,82,0.3)]"
      >
        <div class="flex items-center justify-center text-lg font-semibold">
          {{ chatboxName }}
        </div>
        <div class="flex items-center justify-center gap-1">
          <!-- Ayarlar icon -->
          <button
            @click="showSettingsMenu = !showSettingsMenu"
            class="flex items-center justify-center w-9 h-9 rounded-full hover:bg-inputBackground"
            :class="showSettingsMenu ? 'bg-inputBackground' : ''"
          >
            <svg-icon name="dots" class="w-3.5 h-3.5 rotate-90" />
          </button>
          <!-- Kucult/Buyut icon -->
          <button
            @click="chatBoxWidthIsSmall = !chatBoxWidthIsSmall"
            class="hidden sm:flex items-center justify-center w-9 h-9 rounded-full hover:bg-inputBackground"
            :class="chatBoxWidthIsSmall ? '' : 'bg-inputBackground'"
          >
            <svg-icon name="resize" class="w-3.5 h-3.5" />
          </button>
          <!-- Kapat icon -->
          <button
            @click="showChatBox = !showChatBox"
            class="flex items-center justify-center w-9 h-9 rounded-full hover:bg-inputBackground"
          >
            <svg-icon name="chevron-down" class="w-3.5 h-3.5" />
          </button>
        </div>

        <!-- ayarlar menusu START -->
        <div
          class="flex flex-col gap-3 absolute top-[64px] left-0 px-3 py-2 mx-3 w-[calc(100%_-_24px)] rounded-xl bg-white text-black-800 z-10 shadow-[0_1px_28px_0px_rgba(82,82,82,0.3)] transition-all"
          :class="
            showSettingsMenu ? '' : 'scale-50 opacity-0 pointer-events-none'
          "
        >
          <div class="flex justify-between items-center gap-3">
            <div class="text-sm font-semibold">Yazı tipi boyutu</div>
            <div class="flex items-center gap-3">
              <button
                @click="resizeFontSize()"
                class="flex items-center justify-center text-dolgerBlue w-6 h-6 font-semibold rounded-full hover:bg-dolgerBlue hover:text-white transition-all"
                :class="
                  messageFontSize == 14
                    ? 'scale-50 opacity-0 pointer-events-none'
                    : ''
                "
              >
                <svg-icon name="reset" class="w-3.5 h-3.5" />
              </button>
              <div
                class="flex items-center gap-2 bg-dolgerBlue bg-opacity-10 rounded-full shadow-inner"
              >
                <button
                  @click="resizeFontSize('minus')"
                  class="flex items-center justify-center w-6 h-6 text-dolgerBlue font-semibold rounded-full"
                  :disabled="disableMinusButtonOfFontSize"
                  :class="
                    !disableMinusButtonOfFontSize
                      ? 'hover:bg-dolgerBlue hover:text-white'
                      : 'opacity-20'
                  "
                >
                  -
                </button>
                <div
                  class="text-sm font-semibold text-dolgerBlue w-8 cursor-default"
                >
                  {{ messageFontSize }}
                </div>
                <button
                  @click="resizeFontSize('plus')"
                  class="flex items-center justify-center w-6 h-6 text-dolgerBlue font-semibold rounded-full"
                  :disabled="disablePlusButtonOfFontSize"
                  :class="
                    !disablePlusButtonOfFontSize
                      ? 'hover:bg-dolgerBlue hover:text-white'
                      : 'opacity-20'
                  "
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- ayarlar menusu END -->
      </header>
      <ul
        style="overflow-anchor: none"
        ref="chatbox"
        class="flex flex-col gap-3 sm:h-[calc(100vh_-_316px)] h-[calc(100vh_-_188px)] sm:max-h-[664px] overflow-y-auto px-3 py-4 text-left main-div bg-gray-100"
      >
        <!-- mesajlar burada yaziliyor -->
      </ul>
      <hr />
      <div class="flex flex-col">
        <div
          class="flex items-center justify-center gap-1 h-[16px] max-h-[16px] w-full gradient-bg text-white font-semibold text-2xs text-dolgerBlue px-3 shadow-[0_-1px_10px_rgba(82,82,82,0.3)] overflow-hidden"
        >
          Soru sorma limitiniz :
          <transition name="slide-fade" mode="out-in">
            <div :key="userMessageLimit" class="text-2xs">
              {{ userMessageLimit }}
            </div>
          </transition>
        </div>
        <div
          class="flex items-center justify-center gap-1 w-full bg-white h-[56px] px-3 sm:rounded-b-xl"
        >
          <textarea
            id="chatMessageId"
            placeholder="Mesaj giriniz..."
            class="w-full h-[56px] border-none outline-none resize-none text-sm py-4"
            v-model="message"
          ></textarea>
          <button
            @click="sendMessageButton()"
            id="send-btn"
            class="flex items-center justify-center px-3 text-dolgerBlue font-semibold w-12 h-12 rounded-full"
            :class="[
              message ? 'visible' : 'invisible',
              answerLoading
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-inputBackground',
            ]"
            :disabled="answerLoading"
          >
            <svg-icon name="send" class="w-5 h-5" />
          </button>
        </div>
      </div>

      <!-- ilk giren kullanici icin tum sayfayi kaplayacak bilgilendirme -->
      <welcomeMessage
        v-if="showWelcomePage"
        @input="showWelcomePageFunc()"
      />
    </div>
  </div>
</template>

<script>
import { sendMessage, getMessages } from "./helpers";
import moment from "moment";
import welcomeMessage from "./components/welcomeMessage.vue";

export default {
  data() {
    return {
      botSvg: require("@/assets/images/chatbot/chat-robot-head.png"),
      chatIconPosition: "right-4 bottom-6", // chat iconunun yeri
      chatBoxPosition: "sm:right-4 sm:bottom-[92px] right-0 bottom-0", // chat kutucugunun yeri
      message: "",
      showChatBox: false, // chatbox-u goster/gizle
      answerLoading: false,
      chatBoxWidthIsSmall: true,
      showSettingsMenu: false, // ayarlar menusu
      // font ayarlari ile ilgili alanlar
      messageFontSize: 14, // hem gelen hem giden mesajlarin font buyuklugu
      disableMinusButtonOfFontSize: false, // font buyuklugu ayarinin eksi buton disable durumu
      disablePlusButtonOfFontSize: false, // font buyuklugu ayarinin arti buton disable durumu
      // chat acilmadan baslangic mesajlari START
      showQuestionYesOrNo: false, // teklif ve evet/hayir ile cevapli kutu show/hide
      showQuestionYesOrNoWithImage: false, // teklif ve evet/hayir ile cevapli ve resimli kutu show/hide
      // chat acilmadan baslangic mesajlari END
      typeWriterMessage: "",
      typeWriterIndex: 0,
      typeWriterSpeed: 20,
      //
      userMessageLimit: 0,
      chatboxName: "Edu Koç", // yapay zekamizin adi
      showWelcomePage: false, // ilk giren kullanici icin hos geldin bildiriminin show/hide degeri burada tutuluyor...
      welcomePageValue: false, // ...chatbot acildiginda bu deger setlenir. Hos geldin mesaji acildigi gibi efekt baslasin diye
    };
  },
  components: {
    welcomeMessage,
  },
  watch: {
    "$route.name"() {
      this.setChatPosition()
    },
    showChatBox(val) {
      // chatbot acilmak icin tiklandiginda ve hosgeldin mesaji henuz kabul edilmediyse calissin
      if (val && this.welcomePageValue) {
        this.showWelcomePage = this.welcomePageValue
      }
    }
  },
  mounted() {
    this.setChatPosition()
    getMessages()
      .then((res) => {
        let resData = res?.data?.data?.reverse();
        const chatbox = this.$refs.chatbox;
        // sistemin ilk mesaji
        if (resData?.length <= 0) {
          chatbox.appendChild(
            this.createChatLi(
              "Merhaba sana nasıl yardımcı olabilirim? 🚀✨",
              "incoming",
              moment().toString()
            )
          );
        }
        resData?.forEach((r) => {
          chatbox.appendChild(
            this.createChatLi(r.sendMessage, "outgoing", r.sendMessageDate)
          );
          chatbox.appendChild(
            this.createChatLi(r.answerMessage, "incoming", r.answerMessageDate)
          );
        });
        this.userMessageLimit = res?.data?.otherData?.remainingQuestionCount;
        this.welcomePageValue =
          res?.data?.otherData?.aiChatAcceptDate == null ? true : false;
      })
      .finally(() => {
        setTimeout(() => {
          this.scrollToBottomFunc(true);
        }, 100);
      });
    document
      .getElementById("chatMessageId")
      .addEventListener("keypress", this.messageKeypress);
  },
  beforeDestroy() {
    document
      .getElementById("chatMessageId")
      ?.removeEventListener("keypress", this.messageKeypress);
  },
  methods: {
    messageKeypress(event) {
      // enter zaten yeni satir demek. shift+enter tiklanirsa da ayni. Sadece shift+enter zamani mesaj gonderilmesi engellendi.
      if ((event.keyCode == 13 && event.shiftKey) || this.answerLoading) {
        return;
      }
      if (event.keyCode == 13) {
        this.sendMessageButton();
        event.preventDefault();
      }
    },
    createChatLi(message, sendType, dateTime) {
      const chatLi = document.createElement("li");
      let chatContent;
      if (sendType === "outgoing") {
        chatLi.classList.add("flex", "gap-3", "justify-end");
        chatContent = `<div class="flex flex-col gap-0.5 max-w-[75%] group" tabindex="0">
            <div
              style="font-size:${this.messageFontSize}px"
              class="break-words text-white px-4 py-3 rounded-tl-xl rounded-bl-xl rounded-tr-xl gradient-bg messageClass transition-all"
            >${message}</div>
            <div class="text-2xs text-right text-dolgerBlue font-medium hidden group-focus:block pr-4">${this.dateFormat(
              dateTime
            )}</div>
          </div>`;
      } else if (sendType === "incoming") {
        chatLi.classList.add("flex", "items-end", "gap-1.5");
        chatContent = `<div class="relative flex items-center justify-center w-7 h-7 text-dolgerBlue">
            <img src="${this.botSvg}"/>
            <div class="absolute -right-0.5 -bottom-0.5 w-2.5 h-2.5 border-2 border-white bg-green-400 rounded-full"></div>
          </div>
          <div class="flex flex-col gap-0.5 max-w-[75%] group" tabindex="0">
            <div
              style="font-size:${this.messageFontSize}px"
              class="break-words px-4 py-3 rounded-tl-xl rounded-tr-xl rounded-br-xl bg-white whitespace-pre-wrap messageClass transition-all shadow-[0_1px_6px_4px_rgba(28,123,255,0.3)]"
            >${message}</div>
            <div class="text-2xs text-left text-dolgerBlue font-medium hidden group-focus:block pl-4">${this.dateFormat(
              dateTime
            )}</div>
          </div>`;
      } else if (sendType === "incoming-loading") {
        chatLi.classList.add("flex", "items-end", "gap-1.5");
        chatContent = `<div class="relative flex items-center justify-center w-7 h-7 text-dolgerBlue">
            <img src="${this.botSvg}"/>
            <div class="absolute -right-0.5 -bottom-0.5 w-2.5 h-2.5 border-2 border-white bg-green-400 rounded-full"></div>
          </div>
          <div
            style="font-size:${this.messageFontSize}px"
            class="max-w-[75%] px-4 py-3 rounded-tl-xl rounded-tr-xl rounded-br-xl bg-white italic messageClass transition-all shadow-[0_1px_6px_4px_rgba(255,255,0,0.3)]"
          >${message}</div>`;
      }
      chatLi.innerHTML = chatContent;
      return chatLi;
    },
    sendMessageButton() {
      if (!this.message) return;
      // kullanicinin yazdigi mesaji chatboxa ekle
      const nowDate = moment().toString();
      const chatbox = this.$refs.chatbox;
      chatbox.appendChild(this.createChatLi(this.message, "outgoing", nowDate));
      chatbox.appendChild(
        this.createChatLi("Düşünüyorum...", "incoming-loading")
      );
      this.scrollToBottomFunc(true);
      let tempMessage = this.message;
      this.message = "";
      this.answerLoading = true;
      let pageUrl = window?.location?.pathname;
      let basePacketId = localStorage.getItem("basePacketId");
      sendMessage(tempMessage, pageUrl, basePacketId)
        .then((res) => {
          chatbox.appendChild(
            this.createChatLi("", "incoming", res.data.answerMessageDate)
          );
          this.typeWriter(res.data.answerMessage); // efektle ekrana yazilir
          this.userMessageLimit = res.data.remainingQuestionCount;
        })
        .finally(() => {
          this.answerLoading = false;
          this.deleteLoadingMessage();
          setTimeout(() => {
            this.scrollToBottomFunc(true);
          }, 100);
        });
    },
    scrollToBottomFunc(isSmoothBehavior) {
      const chatbox = this.$refs.chatbox;
      chatbox.scrollTo({
        top: chatbox.scrollHeight,
        ...(isSmoothBehavior && { behavior: "smooth" }),
      });
    },
    deleteLoadingMessage() {
      const chatbox = this.$refs.chatbox;
      let allMessagesLength = chatbox.childNodes.length;
      chatbox.removeChild(chatbox.childNodes[allMessagesLength - 2]); // sondan 1 onceki yani loading mesajini sil
    },
    dateFormat(date) {
      if (date) {
        let dateToCheck = moment(date);
        let today = moment().startOf("day");
        if (dateToCheck.isSame(today, "day")) {
          return "bugün " + moment(date).format("HH:mm");
        } else {
          return moment(date).format("DD.MM.YYYY HH:mm");
        }
      } else return "";
    },
    resizeFontSize(typeOfResize = "reset") {
      const lowerLimit = 8;
      const upperLimit = 32;
      var el = document.getElementsByClassName("messageClass");
      // arti butonu tiklanirsa
      if (typeOfResize == "plus") {
        this.messageFontSize += 1; // font buyuklugunu 1px arttir
      }
      // eksi butonu tiklanirsa
      else if (typeOfResize == "minus") {
        this.messageFontSize -= 1; // font buyuklugunu 1px azaltir
      }
      // reset butonu tiklanirsa
      else {
        this.messageFontSize = 14;
      }
      if (el?.length > 0) {
        for (let e = 0; e < el.length; e++) {
          el[e].style.fontSize = this.messageFontSize + "px";
        }
      }

      // buton disable/enable durumlari
      this.disableMinusButtonOfFontSize = false;
      this.disablePlusButtonOfFontSize = false;
      if (this.messageFontSize <= lowerLimit) {
        this.disableMinusButtonOfFontSize = true;
      }
      if (this.messageFontSize >= upperLimit) {
        this.disablePlusButtonOfFontSize = true;
      }
    },
    typeWriter(text) {
      this.typeWriterMessage = text;
      this.typeWriterFunc();
    },
    typeWriterFunc() {
      const chatbox = this.$refs.chatbox; // mesaj yazilacak alan
      const lastDiv = chatbox?.lastChild; // son mesaj div-i
      const typedText = lastDiv?.lastChild?.firstChild?.nextElementSibling; // son mesaj div-i icinde ilk resim ve ikinci metin oldugu icin burasi boyle
      if (!typedText || !this.typeWriterMessage) return; // hata durumunda cik
      // gecici bir div kullanarak HTML icerigini ayristir
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.typeWriterMessage;
      // ayristirilmis dugumleri bir array olarak al
      const nodes = Array.from(tempDiv.childNodes);
      if (this.typeWriterIndex < nodes?.length) {
        const currentNode = nodes[this.typeWriterIndex];
        if (currentNode.nodeType === Node.TEXT_NODE) {
          // eger dugum metinse
          const text = currentNode.textContent;
          this.appendTextCharacterByCharacter(typedText, text);
        } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
          // eger düğüm HTML ise
          typedText.appendChild(currentNode.cloneNode(true));
          this.typeWriterIndex++;
          setTimeout(() => this.typeWriterFunc(), this.typeWriterSpeed);
        }
      } else {
        this.answerLoading = false; // ekrana yazma bittiginde mesaj gonder buton tekrar aktif yapilir
        this.scrollToBottomFunc(true);
        this.typeWriterIndex = 0;
        this.typeWriterMessage = "";
      }
    },
    appendTextCharacterByCharacter(targetElement, text) {
      let charIndex = 0;
      const typeChar = () => {
        if (charIndex < text.length) {
          this.answerLoading = true; // ekrana yazma efekti varken tekrar mesaj gonderemesin
          // yazarken surekli asagi scroll et
          this.scrollToBottomFunc(true);
          targetElement.innerHTML += text[charIndex];
          charIndex++;
          setTimeout(typeChar, this.typeWriterSpeed);
        } else {
          this.typeWriterIndex++;
          this.typeWriterFunc(); // Bir sonraki dugume gec
        }
      };
      typeChar();
    },
    setChatPosition() {
      // chat iconu bazi sayfalar bir seylerin ustune geldigi icin yerini degismek zorunda kaliyoruz
      // if kosulu icinde yerini degisecek, else icinde eski haline geri donecek
      if (this.$route.name == "basket" || this.$route.name == "test" || this.$route.name == "studentList") {
        this.chatIconPosition = "hidden"
        this.chatBoxPosition = "hidden"
      }
      else {
        this.chatIconPosition = "right-4 bottom-6"
        this.chatBoxPosition = "sm:right-4 sm:bottom-[92px] right-0 bottom-0"
      }
    },
    showWelcomePageFunc(e) {
      this.showWelcomePage = e
      this.welcomePageValue = e
    },
  },
};
</script>

<style scoped>
.pulseBtn {
  box-shadow: 0 0 0 0 rgb(28, 123, 255, 0.4);
  -moz-animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    scale: 1;
    box-shadow: 0 0 0 0 rgb(28, 123, 255, 1);
  }
  70% {
    scale: 0.8;
    box-shadow: 0 0 0 10px rgb(28, 123, 255, 0);
  }
  100% {
    scale: 1.2;
    box-shadow: 0 0 0 50px rgba(28, 123, 255, 0);
  }
}
.chatbox-button {
  border-radius: 10px 10px 50px 50px;
}
.main-div::-webkit-scrollbar {
  width: 4px;
}
.main-div::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}
.main-div::-webkit-scrollbar-thumb {
  background: #b1b1b1;
}

.slide-fade-enter-active {
  transition: all 0.25s ease;
}
.slide-fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter {
  transform: translateY(-2rem);
}
.slide-fade-leave-to {
  transform: translateY(2rem);
}
</style>

<style>
.chatLinkClass {
  font-size: v-bind("messageFontSize") !important;
  color: #1c7bff;
}
</style>